.marker-cluster-small {
	background-color: rgba(33, 181, 134, 0.4);
}
.marker-cluster-small div {
	background-color: rgba(33, 181, 134, 1);
}

.marker-cluster-medium {
  background: rgba(255, 195, 84, 0.4);
}
.marker-cluster-medium div {
  background-color: rgba(255, 195, 84, 1);
}

.marker-cluster-large {
  background: rgba(210, 65, 65, 0.4);
}
.marker-cluster-large div {
  background-color: rgba(210, 65, 65, 1);
}
