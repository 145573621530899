html {
	height: 100%;
	width: 100%;
}
body {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	font-family: sans-serif;
	overflow: hidden;
}
#root {
	height: 100%;
	width: 100%;
}

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i');
