.leaflet-tooltip {
  border: none;
  background-color: black;
  color: white;
}
.leaflet-tooltip-left:before {
  border-left-color: black;
}
.leaflet-tooltip-right:before {
  border-right-color: black;
}
.leaflet-tooltip-top:before {
  border-top-color: black;
}
.leaflet-tooltip-bottom:before {
  border-bottom-color: black;
}
